import * as React from "react";
import { Link } from 'gatsby';
import Layout from "../../templates/layout-landing-page";
import Seo from "../../components/seo";
import uvmLogo from '../../assets/img/welcome/ntm-lp/uvm-mvp-logo.svg';
import calendarsecondaryicn from '../../assets/img/calendar-secondary-icn.svg';
import cursorsecondaryicn from '../../assets/img/cursor-secondary-icn.svg';

const AEPMemberTerm = ({ location }) => {

	return (
		<Layout location={location} heading1="" heading2="">

			<Seo title="Exciting news MVP WellSelect(R) PPO members." meta={[{description: ''}]} bodyclass="aep-member-lp" />
		
			<header>
				<div className="constrained">
					<div className="logo"><img src={uvmLogo} alt="UVM Health &amp; MVP Health Care" width="250" height="49" /></div>
					<h1>Exciting news<br />for MVP WellSelect<sup>&reg;</sup> PPO members.</h1>
				</div>
			</header>

			<main>
				<div className="constrained">
					<h2>Thank you for being a valued member of our MVP Health Care<sup>&reg;</sup> (MVP) family.</h2>
					<p>You may have seen that your current MVP Well Select<sup>&reg;</sup> PPO Medicare Advantage plan will no longer be available in 2023. However, we have an exciting new option &mdash; one that gives you all your current plan benefits and more. UVM Health Advantage plans were designed with the people of Vermont and Northern New York in mind. And guided by doctors from the UVM Health Network and Medicare experts at MVP Health Care.</p>
					
					<h3>Same price. Same provider network. New money-saving benefits.</h3>
					<p>Our <strong>UVM Health Advantage Select PPO plan</strong> has everything your old plan offered, with new benefits:</p>
					
					<div className="testimonial_flexwrap">
						<ul>
							<li>$0 monthly premium</li>
							<li>Preventive and comprehensive dental coverage</li>
							<li>$150 eyewear allowance per year</li>
							<li>24 free one-way rides to or from medical appointments (up to 60 miles per ride)</li>
							<li>FREE meal delivery after an inpatient hospital stay</li>
							<li>FLEXIBLE hearing aid benefit &ndash; choose the device that best fits your needs</li>
							<li>Wellness and care management programs and tools</li>
						</ul>
						<div className="testimonial">
							<blockquote>
								<p>Very easy. Very helpful. <strong>UVM Health Advantage has been a delight!</strong></p>
								<footer>&ndash; Eleanor, Burlington, VT <i>UVM Health Advantage Plan Member</i></footer>
							</blockquote>
						</div>
					</div>

					<h3>Plus, save on many important health care costs.</h3>
					<div className="cost_table-wrapper">
						<div className="cost_table">
							<table cellSpacing="0">
								<caption className="visuallyhidden">Plan benefit comparison between current and new 2023 plans</caption>
								<tbody>
									<tr>
										<th scope="col" className="benefit">Benefit</th>
										<th scope="col" className="currentplan">Your current 2022 plan: <strong>MVP&nbsp;WellSelect<sup>&reg;</sup> PPO</strong></th>
										<th scope="col" className="newplan">Comparable 2023 option: <strong>UVM Health Advantage Select PPO</strong></th>
									</tr>
									<tr>
										<th scope="row" className="rowhead">Primary Care Visits</th>
										<td>In <strong>$0</strong> | Out <strong>$60</strong></td>
										<td>In <strong>$0</strong> | Out <strong>$5</strong></td>
									</tr>
									<tr>
										<th scope="row" className="rowhead">Specialist Visits</th>
										<td>In <strong>$45</strong> | Out <strong>$65</strong></td>
										<td>In <strong>$35</strong> | Out <strong>$50</strong></td>
									</tr>
									<tr>
										<th scope="row" className="rowhead">X-Rays</th>
										<td>In <strong>$60</strong> | Out <strong>$60</strong></td>
										<td>In <strong>$10</strong> | Out <strong>$10</strong></td>
									</tr>
									<tr>
										<th scope="row" className="rowhead">OTC Purchases</th>
										<td><strong>$0</strong></td>
										<td><strong>$25</strong> allowance per quarter</td>
									</tr>
									<tr>
										<th scope="row" className="rowhead">Comprehensive Dental</th>
										<td>Additional coverage available for <strong>$25</strong> a month</td>
										<td><strong>$40</strong> copay; 20-50% co-insurance, up to $1,000 a year</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p><a href="https://www.mvphealthcare.com?wpdmdl=266887&ind=1663257444295" className="download">Download the full comparison (PDF)</a></p>
					</div>
					
					<h3>We make it easy to switch.</h3>
					<p>Your UVM Health Advantage Plan Guide will provide expert guidance to help you understand your options and make it simple to get set up in your new plan. Give us a call at <strong>1-833-825-5886 (TTY 711)</strong> and we&rsquo;ll help you choose a plan with confidence. We are available seven days a week, 8 a.m.-8 p.m. Eastern Time. Rest assured, when you enroll in a UVM Health Advantage plan, there will be no disruption in your service or care.</p>
				</div>
			</main>

			<aside>

				<div className="constrained">
					<div className="ctas secondary">
						<div className="flexwrap">
						
							<div className="secondary-cta">
								<img src={calendarsecondaryicn} alt="" width="60" height="60" className="icon" />
								<p className="head"><b>Talk to us directly.</b></p>
								<p>Search available phone, video or in-person appointments or request a meeting at a time and place that&rsquo;s convenient for you.</p>
								<a href="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling@mvphealthcare.onmicrosoft.com/bookings/" className="button external_button" target="_blank" rel="noreferrer">Search Appointments</a>
								<Link to="/meet-with-us/request-consultation/" className="button secondary">Request a Consultation</Link>
							</div>
							
							<div className="secondary-cta">
								<img src={cursorsecondaryicn} alt="" width="60" height="60" className="icon" />
								<p className="head"><b>Enroll online now</b></p>
								<p>Here you&rsquo;ll find the enrollment forms you&rsquo;ll need to make the switch on your own. If you have any questions along the way, we&rsquo;re here to help.</p>
								<Link to="/enroll-now/" className="button secondary">Begin Enrollment</Link>
							</div>

						</div>
					</div>
					
					<p className="disclaimer">Safety guidelines from federal and state agencies regarding COVID-19 have been evolving. Currently, you must be fully vaccinated to attend in-person appointments at the MVP Health Care office in Williston, VT, and wear a face masks while on site, regardless of vaccination status. If you are not vaccinated, please select a video or phone appointment.</p>
					
					<div className="linkcard currentmembers">
						<a href="/already-an-mvp-member/">For more information for current MVP Health Care Medicare Advantage members, click here.</a>
					</div>
					
					<div className="linkcard plans">
						<a href="/plans/">To view the full family of UVM Health Advantage plans, click here.</a>
					</div>
					
				</div>
				
			</aside>
		</Layout>
	)
}

export default AEPMemberTerm
